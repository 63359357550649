import Vue from 'vue'
import App from './App.vue'
import AppCreateTask from './AppCreateTask.vue'
import VueI18n from 'vue-i18n'
import AsyncComputed from 'vue-async-computed'
import AppViewTasks from './AppViewTasks.vue'

Vue.use(VueI18n);
Vue.use(AsyncComputed);

Vue.config.productionTip = false

const messages = {
  en_US: {
    message: {
      panel_title: 'Sales visit',
      panel_sales_title: 'Sales visit',
      panel_aftersales_title: 'Aftersales visit',
      create_task: 'Create task',
      save_task: 'Save task',
      dealer_info: 'Dealer info',
      customer_info: 'Customer info',
      dealer_code: 'Dealer Code',
      dealer_name: 'Dealer Name',
      interview_date: 'Interview Date',
      firstname: 'First name',
      lastname: 'Last name',
      name: 'Name',
      phonenumber: 'Phone number',
      email: 'Email',
      model: 'model',
      licencePlate: 'Licence plate',
      specific_reasons: 'Specified reasons',
      performed_task: 'Tasks to be performed',
      create_task_txt: 'Tasks',
      extra_description: 'Extra description',
      switch_views: 'Switch views',
      creating: 'Creating...',
      solution: 'Solution',
      comments: 'Comments',
      comments_and_activities:'Comments & activities log',
      activities: 'Activities Log',
      country: 'Country',
      hub_manager: 'Hub manager',
      hub_member: 'Hub member',
      dealer_manager: 'Dealer manager',
      dealer_member: 'Dealer member',
      text: 'text...',
      priority: 'Priority',
      date: 'Date',
      impact: 'Impact',
      service_date: 'Service Date',
      appointed_to: 'Appointed to',
      task_create_title: 'Task Create',
      task_edit_title: 'Task Edit',
      error_task_create: 'Ups! Something went wrong, please contact support team.',
      success_task_create: 'Task was created.',
      details: 'details',
      input_user_placeholder: 'Type name or e-mail',
      select_kpi: 'Select KPI',
      file: 'File',
      language: 'Language',
      client_name: 'Client name',
      tasks: 'Tasks',
      vin: 'VIN',
      answer: 'Answer',
      option: 'Option',
      write_comment: 'Place a comment',
      add_comment: 'Add Comment',
      customer_contacted: 'Customer Contacted',
      customer_contacted_value: 'Yes',
      task_info: 'After completing the task or tasks, please tick them below. After ticking the task(s), you can complete the task at the bottom of this form.',
      type_input_placeholder: "Type…",
      low: "low",
      average: "average",
      high: "high",
      task_info_create: 'Add at least one task be one task below with the plus icon "+"'
    }
  },
  nl_NL: {
    message: {
      panel_title: 'Sales bezoek',
      panel_sales_title: 'Sales bezoek',
      panel_aftersales_title: 'Aftersales bezoek',
      create_task: 'Taak aanmaken',
      save_task: 'Taak opslaan',
      dealer_info: 'Dealer info',
      customer_info: 'Klant info',
      dealer_code: 'Dealernummer',
      dealer_name: 'Dealernaam',
      interview_date: 'Interviewdatum',
      firstname: 'Voornaam',
      lastname: 'Achternaam',
      name: 'Naam',
      phonenumber: 'Telefoon',
      email: 'E-mail',
      model: 'Model',
      licencePlate: 'Kenteken',
      specific_reasons: 'Opgegeven redenen',
      performed_task: 'Uitgevoerde taken',
      create_task_txt: 'Taken',
      extra_description: 'Extra omschrijving',
      switch_views: 'Wissel van weergave',
      creating: 'Aanmaken...',
      solution: 'Oplossing',
      comments: 'Opmerkingen',
      comments_and_activities: 'Activiteiten log',
      activities: 'Activiteitenlogboek',
      country: 'Land',
      hub_manager: 'Hub manager',
      hub_member: 'Hub medewerker',
      dealer_manager: 'Dealer manager',
      dealer_member: 'Dealer medewerker',
      text: 'tekst...',
      priority: 'Prioriteit',
      date: 'Datum',
      impact: 'Impact',
      service_date: 'Onderhoudsdatum',
      appointed_to: 'Toegewezen aan',
      task_create_title: 'Taak aanmaken',
      task_edit_title: 'Taak bewerken',
      error_task_create: 'Oeps! Er gaat iets mis, zoek contact met het support team',
      success_task_create: 'Taak is aangemaakt',
      details: 'Details',
      input_user_placeholder: 'Type naam of e-mail',
      select_kpi: 'Selecteer KPI',
      file: 'Bestand',
      language: 'Taal',
      client_name: 'Klantnaam',
      tasks: 'Taken',
      vin: 'VIN',
      write_comment: 'Schrijf een update',
      add_comment: 'Opslaan',
      answer: 'Antwoord',
      option: 'Optie',
      customer_contacted: 'Klant gecontact',
      customer_contacted_value: 'Ja',
      task_info: 'Nadat u de taak of taken heeft gedaan, vinkt u ze hieronder af. Daarna kunt u onderaan dit formulier de taak afronden.',
      type_input_placeholder: "Type…",
      low: "laag",
      average: "middel",
      high: "hoog",
      task_info_create: 'Voeg hieronder met behulp van het plusteken "+" tenminste 1 taak toe'
    }
  },
  nl_BE: {
    message: {
      panel_title: 'Sales bezoek',
      panel_sales_title: 'Sales bezoek',
      panel_aftersales_title: 'Aftersales bezoek',
      create_task: 'Taak aanmaken',
      dealer_info: 'Dealer info',
      save_task: 'Taak opslaan',
      customer_info: 'Klant info',
      dealer_code: 'Dealernummer',
      dealer_name: 'Dealernaam',
      interview_date: 'Interviewdatum',
      firstname: 'Voornaam',
      lastname: 'Achternaam',
      name: 'Naam',
      phonenumber: 'Telefoon',
      email: 'E-mail',
      model: 'Model',
      licencePlate: 'Nummerplaat',
      specific_reasons: 'Opgegeven redenen',
      performed_task: 'Uit te voeren taken',
      create_task_txt: 'Taken',
      extra_description: 'Extra omschrijving',
      switch_views: 'Wissel van weergave',
      creating: 'Aanmaken...',
      solution: 'Oplossing',
      comments: 'Opmerkingen',
      comments_and_activities: 'Activiteiten log',
      activities: 'Activiteitenlogboek',
      country: 'Land',
      hub_manager: 'Hub manager',
      hub_member: 'Hub medewerker',
      dealer_manager: 'Dealer manager',
      dealer_member: 'Dealer medewerker',
      text: 'tekst...',
      priority: 'Prioriteit',
      date: 'Datum',
      impact: 'Impact',
      service_date: 'Datum bezoek',
      appointed_to: 'Toegewezen aan',
      task_create_title: 'Taak aanmaken',
      task_edit_title: 'Taak bewerken',
      error_task_create: 'Oeps! Er gaat iets mis, zoek contact met het support team',
      success_task_create: 'Taak is aangemaakt',
      details: 'Details',
      input_user_placeholder: 'Type naam of e-mail',
      select_kpi: 'Selecteer KPI',
      file: 'Bestand',
      language: 'Taal',
      client_name: 'Klant naam',
      tasks: 'Taken',
      vin: 'VIN',
      write_comment: 'Schrijf een update',
      add_comment: 'Opslaan',
      answer: 'Antwoord',
      option: 'Optie',
      customer_contacted: 'Klant gecontacteerd',
      customer_contacted_value: 'Ja',
      task_info: 'Nadat u de taak of taken heeft gedaan, vinkt u ze hieronder af. Daarna kunt u onderaan dit formulier de taak afronden.',
      type_input_placeholder: "Type…",
      low: "laag",
      average: "middel",
      high: "hoog",
      task_info_create: 'Voeg hieronder met behulp van het plusteken "+" tenminste 1 taak toe'
    }
  },
  fr_BE: {
    message: {
      panel_title: 'Visite vente',
      panel_sales_title: 'Visite vente',
      panel_aftersales_title: 'Visite après vente ',
      create_task: 'Créer une tâche',
      save_task: 'Enregistrer la tâche',
      dealer_info: 'Info concessionnaire',
      customer_info: 'Info client',
      dealer_code: 'Numéro concession',
      dealer_name: 'Nom concession',
      interview_date: "Date de l'enquête",
      firstname: 'Prénom',
      lastname: 'Nom de famille',
      name: 'Nom',
      phonenumber: 'Téléphone',
      email: 'E-mail',
      model: 'Modèle',
      licencePlate: 'Plaque d’immatriculation',
      specific_reasons: 'Raisons spécifiées',
      performed_task: 'Tâches à effectuer',
      create_task_txt: 'Tâches',
      extra_description: 'Description supplémentaire',
      switch_views: 'Changer de vue',
      creating: 'Créer...',
      solution: 'Solution',
      comments: 'Commentaires',
      comments_and_activities: 'Journal',
      activities: "Journal d'activités",
      country: 'Pays',
      hub_manager: 'Manager pivot',
      hub_member: 'Employé pivot',
      dealer_manager: 'Manager affaire',
      dealer_member: 'Employé affaire',
      text: 'texte...',
      priority: 'Priorité',
      date: 'Date',
      impact: 'Impact',
      service_date: 'Date de visite',
      appointed_to: 'Attribué à',
      task_create_title: 'Créer une tâche',
      task_edit_title:  'Mise à jour tâche',
      error_task_create: 'Erreur',
      success_task_create: 'Tâche enregistrée',
      details: 'Détails',
      input_user_placeholder: "Taper le nom ou l'e-mail",
      select_kpi: 'Sélectionnez KPI',
      file: 'Document',
      language: 'Langue',
      client_name: 'Nom client',
      tasks: 'Tâches',
      vin: 'VIN',
      write_comment: 'Écrire une mise à jour',
      add_comment: 'Sauvegarder',
      answer: 'Réponse',
      option: 'Option',
      customer_contacted: 'Client contacté',
      customer_contacted_value: 'Oui',
      task_info: 'Après avoir terminé la ou les tâches, veuillez les cocher ci-dessous. Après avoir coché la ou les tâches, vous pouvez compléter la tâche en bas de ce formulaire.',
      type_input_placeholder: "Taper…",
      low: "basse",
      average: "moyenne",
      high: "haute",
      task_info_create: "Ajoutez au moins 1 tâche ci-dessous à l'aide du signe plus '+'"
    }
  }
}

/* eslint-disable no-unused-vars */
export const i18n = new VueI18n({
  locale: 'en_US',
  messages,
})
/* eslint-enable no-unused-vars */

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')

new Vue({
  i18n,
  render: h => h(AppCreateTask),
}).$mount('#app_createTask')

new Vue({
  i18n,
  render: h => h(AppViewTasks),
}).$mount('#app_viewTasks')
