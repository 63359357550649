<template>
  <div class="FormSelect_container">
    <div class="FormSelect_inputContainer">
      <input :placeholder='$t("message.text")' v-model="title" :class="['FormSelect_input', title_error ? 'error' : '' ]" />
    </div>
    <SelectForm :error="priority_error" v-model="priority" :placeholder='$t("message.priority")' 
      v-bind:options="priority_options" icon="fas fa-exclamation" />
    <SelectForm :error="impact_error" v-model="impact" :placeholder='$t("message.impact")' 
      :options="impact_options" icon="fas fa-bolt" />
    <div :class="['FormSelect_calendarContainer', cal_data_error ? 'error' : '' ]">
      <div class="FormSelect_calendarPrefix">
        <i class="far fa-calendar-alt"></i>
      </div>
      <datepicker :placeholder='$t("message.date")' v-model="cal_data" calendar-class="calendar-position" 
        :class="['FormSelect_calendar', cal_data_error ? 'error' : '' ]" :disabled-dates="pastDates" />
    </div>
    <div class="FormSelect_userPicker">
      <UserPicker v-on:selectedUsers="onSelectedUsers" :error="users_error" ref="usersComponent"/>
    </div>
    <div class="FormSelect_selectContainer">
      <div class="FormSelect_motPrefix">
        <i class="fas fa-crosshairs"></i>
      </div>

      <select class="FormSelect_select" v-model="kpi" @change="selectMot(kpi)">
        <option :value="null" selected>{{ $t("message.select_kpi") }}</option>
        <option v-for="item in kpi_options" v-bind:key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>

    </div>
  </div>
</template>

<script>
  import SelectForm from './form/SelectForm.vue'
  import UserPicker from './UserPicker.vue'
  import axios from 'axios'

  import Datepicker from 'vuejs-datepicker'
          
  export default {
    data() {
      return {
        cal_data: '',
        cal_data_error: false,
        kpi: null,
        value: '',
        title: '',
        title_error: false,
        priority_error: false,
        impact_error: false,
        priority: '',
        impact: '',
        dataObject: Object,
        selected_mot: '',
        status: false,
        users: [],
        users_error: false,
        kpi_options: [],
        kpi_id: ''
      }
    },
    props: {
      priority_options: Array,
      impact_options: Array,
      motLabels: Array
    },
    watch: {
      kpi: function() {
        if (!this.title)
          this.title_error = true
        if (!this.priority)
          this.priority_error = true
        if (!this.impact)
          this.impact_error = true
        if (!this.cal_data)
          this.cal_data_error = true
        if(this.users.length == 0)
          this.users_error = true
        this.checkForm()
        this.sendForm()
      },
      title: function() { 
        if (this.title)
          this.title_error = false
        this.checkForm()
        this.sendForm() 
      },
      priority: function() { 
        if (this.priority)
          this.priority_error = false
        this.checkForm()
        this.sendForm()
      },
      cal_data: function() { 
        if (this.cal_data)
          this.cal_data_error = false
        this.checkForm()
        this.sendForm() 
      },
      impact: function() { 
        if (this.impact)
          this.impact_error = false 
        this.checkForm()
        this.sendForm()
      },
      users: function() { 
        if (this.users) {
          this.users_error = false
        } 
        this.checkForm()
        this.sendForm()
      }
    },
    components: {
      SelectForm,
      Datepicker,
      UserPicker
    },
    mounted () {
      var kpi_location = document.querySelector('#js-kpi_location') ? document.querySelector('#js-kpi_location').textContent : ''
      axios.get('/kpi/names?taskmanager_enabled=1&mot=true&type=' + kpi_location).then(response => {
        this.kpi_options = response.data
      })
      .catch(e => {
        console.log(e)
      })
    },
    computed: {
      pastDates: function () {
        var current_date = new Date()
        var day = current_date.getDate()
        var month = current_date.getMonth()
        var year = current_date.getFullYear()
        var state = {
          disabledDates: {
            to: new Date(year, month, day)
          }
        }
        return state.disabledDates
      }
    },
    methods: {
      selectMot(selected_id) {
        this.kpi_id = selected_id
        this.kpi_options.forEach(element => {
          if(element.id == selected_id) {
            this.selected_mot = element.mot    
          }
        })
        this.checkForm()
        this.sendForm()
      },
      sendForm(){ 
        this.dataObject = {
          title: this.title,
          date: this.cal_data,
          priority: this.priority,
          impact: this.impact,
          mot_id: this.selected_mot,
          kpi_id: this.kpi_id,
          form_status: this.status
        }
        this.$emit('formDone', this.dataObject)
      },
      onSelectedUsers(value){
        this.users = value
        if(this.users.length == 0)
          this.users_error = true
        else
          this.users_error = false
        this.$emit('usersDone', value)
      },
      checkForm(){
        if(this.cal_data && this.title && this.impact && this.priority && this.selected_mot && this.users.length > 0) {
          this.status = true
        }
        else
          this.status = false
      }
    }
  }
</script>

<style scoped lang="less">
  .FormSelect {
    &_container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      padding-bottom: 20px;
      float: left;
      padding: 0px 15px;
      width: 100%;
      box-sizing: border-box;
      @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    /deep/ &_calendar input {
      width: 100%;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid #EFEFF0;
      border-radius: 3px;
      padding: 3px 10px 3px 32px;
      font-size: 12px;
      color: #666666;
      background: #FBFBFB;
    }
    /deep/ &_calendar.error input {
      border-color: #ff2400;
    }
    &_selectContainer {
      width: 100%;
      position: relative;
      @media (min-width: 992px) {
        grid-column: span 3;
      }
    }
    &_motPrefix {
      background: #efeff0;
      color: #666;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px 2px;
    }
    &_select {
      width: 100%;
      height: 30px;
      box-sizing: border-box;
      font-size: 12px;
      border: 1px solid #EFEFF0;
      border-radius: 3px;
      padding: 5px 10px 5px 32px;
      font-family: 'Open Sans', sans-serif;
      background: #FBFBFB;
      color: #666666;
    }
    &_inputContainer {
      width: 100%;
      position: relative;
      @media (min-width: 992px) {
        grid-column: span 3;
      }
    }
    &_input {
      width: 100%;
      height: 34px;
      box-sizing: border-box;
      font-size: 14px;
      border: 1px solid #EFEFF0;
      border-radius: 3px;
      padding: 3px 10px;
      font-family: 'Open Sans', sans-serif;
      position: relative;
      background: #FBFBFB;
      color: #666666;
      &.error {
        border-color: #ff2400;
      }
    }
    &_userPicker {
      @media (min-width: 992px) {
        grid-column: span 3;  
      }
    }
    &_calendarPrefix {
      background: #efeff0;
      color: #666;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px 2px;
    }
    &_calendarContainer {
      width: 100%;
      position: relative;
      &.error .FormSelect_calendarPrefix {
        background: #ff2400;
        color: #fff;
      }
    }
  }
  
  /deep/ .calendar-position {
    right: 0px;
  }
  
  /deep/ .vdp-datepicker__calendar {
    font-family: 'Open Sans', sans-serif;
  }
</style>