<template>
  <div>
    <PanelVerbatim 
      v-bind:class="{ 'open' : openVerbatimPanel }"
      :data_dealer="interview"
      v-on:close-verbatim="openVerbatimPanel = false"
      :pdf_link="pdf_link"
    />
    <PanelCreateTask
      v-bind:class="{ 'open' : openPanel }"
      v-on:cancel-task="cancelTask" 
      :edit_data="edit_data"
      v-on:open-verbatim="openVerbatim"
      v-on:close-verbatim="closeVerbatim"
    />
  </div>
</template>

<script>
  import PanelCreateTask from './components/PanelCreateTask.vue'
  import PanelVerbatim from './components/PanelVerbatim.vue'

  import axios from 'axios'
  
  export default {
    data() {
      return {
        openPanel: false,
        openVerbatimPanel: false,
        edit_data: {},
        interview: {},
        pdf_link: ''
      }
    },
    components: {
      PanelCreateTask,
      PanelVerbatim
    },
    mounted: function() {
      try{
        var ref = this
        document.querySelectorAll('.js-create-task').forEach(item => {
          item.addEventListener('click', e => {
            ref.startPanel()
          })
        });
        
        this.$i18n.locale = 'en_US'
        this.$i18n.locale = document.querySelector('#js-language').textContent

        document.querySelectorAll('.js-viewTask > *').forEach(item => {
          var apiId = item.parentNode.id;
          if (!item.classList.contains('notClickable')) {
            item.addEventListener('click', e => {
              ref.editTask(apiId)
            })
          }
        })
      } catch{
        console.error();
      }
    },
    methods: {
      startPanel(){
        this.openPanel = !this.openPanel
      },
      cancelTask(){
        this.openPanel = !this.openPanel
      },
      editTask(apiId){
        var apiLink = '/task-manager/task/' + apiId
        axios.get(apiLink).then(
          response => {
            this.edit_data = response.data
            this.openPanel = !this.openPanel
          }
        )
      },
      openVerbatim(val){
        this.openVerbatimPanel = true
        this.pdf_link = val.replace('json','pdf')
        axios.get(val).then(
          response => {
            this.interview = response.data
          }
        )
      },
      closeVerbatim(){
        this.openVerbatimPanel = false
      }
    }
  }
</script>
<style lang="less">
    
  /deep/ body {
    overflow-x: hidden;
  }
</style>
