<template>
  <div :class="['TaskBox', open ? 'open' : 'closeup' ]">
    <div class="TaskBox_header" v-on:click="open = !open">
      <div class="TaskBox_title">
        <i :class="icon"></i>
        {{ title }}
      </div>
    </div>
    <div class="TaskBox_content" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        open: true
      }
    },
    props: {
      title: String,
      icon: String
    }
  }
</script>

<style scoped lang="less">
  .TaskBox {
    width: 100%;
    margin-bottom: 20px;
    float: left;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    position: relative;
    &.open:after {
      content: "\f077";
      font-family: "Font Awesome 5 Free"; 
      font-weight: 900;
      color: #D0D0D0;
      position: absolute;
      right: 15px;
      top: 10px;
    }
    &.closeup:after {
      content: '\f078';
      font-family: "Font Awesome 5 Free"; 
      font-weight: 900;
      color: #D0D0D0;
      position: absolute;
      right: 15px;
      top: 10px;
    }
    &_header {
      width: 100%;
      float: left;
      height: 42px;
      background: #EFEFF0;
      border-radius: 4px;
      cursor: pointer;
    }
    &_title {
      float: left;
      padding: 10px 10px;
      box-sizing: border-box;
      color: #666666;
      font-size: 14px;
      text-align: left;
      width: calc(~'100% - 30px');
    }
    &_content {
      padding: 10px 10px;
      float: left;
      width: 100%;
      box-sizing: border-box;
      background: #FBFBFB;
      border-radius: 3px;
      font-size: 12px;
    }
  }
</style>