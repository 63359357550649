<template>
  <div class="Panel">
    <div class="Panel_header">
      
      <button class="Panel_cancelBtn" v-on:click="$emit('close-verbatim')">
        <i class="fas fa-chevron-right"></i>
      </button>

      <div class="Panel_title" v-if="data_dealer.projectId === 1">{{ $t("message.panel_sales_title") }}</div>
      <div class="Panel_title" v-if="data_dealer.projectId === 2">{{ $t("message.panel_aftersales_title") }}</div>
      <div class="Panel_title" v-if="data_dealer.projectId === 3">{{ $t("message.panel_sales_title") }}</div>
      <a :href="pdf_link" target="_blank">
        <button class="Panel_pdf">
          <i class="far fa-file-pdf"></i>
        </button>
      </a>

    </div>

    <BasicData
      :dealer_code="data_dealer.codeDealer"
      :dealer_name="data_dealer.dealerName"
      :interview_date="data_dealer.dateInterview"
      :client="data_dealer.client"
      :date_service="data_dealer.dateService"
    />

    <div class="Panel_body">
      <div v-for="item in data_dealer.questions" v-bind:key="item.id">
        <multi-response v-if="item.type === 'multi-response'" :question="item"/>
        <single-response v-else-if="item.type === 'single-response'" :question="item"/>
        <numeric v-else-if="item.type === 'numeric'" :question="item"/>
        <open-answer v-else :question="item"/>
      </div>
    </div>
  </div>
</template>

<script>
  import BasicData from './BasicData.vue'

  import SingleResponse from './answers/SingleResponse.vue'
  import MultiResponse from './answers/MultiResponse.vue'
  import Numeric from './answers/Numeric.vue'
  import OpenAnswer from './answers/OpenAnswer.vue'

  export default {
    components: {
      SingleResponse,
      MultiResponse,
      Numeric,
      OpenAnswer,
      BasicData
    },
    props: {
      data_dealer: Object,
      pdf_link: String
    }
  }
</script>

<style scoped lang="less">
  .Panel {
    width: 100%;
    background: #fff;
    box-shadow: -1px 0px 16px #00000029;
    box-sizing: border-box;
    height: 100vh;
    overflow: auto;
    font-family: 'Open Sans', sans-serif;
    position: fixed;
    top: 0px;
    right: -490px;
    transition: right 1s;
    float: left;
    z-index: 9998;
    padding-bottom: 20px;
    @media (min-width: 470px) {
      z-index: 9999;
      width: 470px;
    }
    &.open {
      right: 470px;
    }
    &_pdf, &_pdf:hover, &_pdf:focus {
      background: transparent;
      border: 0px solid transparent;
      padding: 0px;
      color: #6f6f6f;
      float: right;
      margin-right: 10px;
      font-size: 20px;
      outline: none;
    }
    &_cancelBtn {
      background: transparent;
      border: 0px solid transparent;
      font-size: 16px;
      color: #EFEFF0;
      text-align: center;
      float: left;
      padding: 2px 0px;
      cursor: pointer;
      margin-right: 10px;
    }
    &_title {
      font-weight: 700;
      color: #666666;
      font-size: 19px;
      float: left;
      line-height: 29px;
    }
    &_header {
      padding: 10px 15px;
      width: 100%;
      float: left;
      box-sizing: border-box;
    }
    &_body {
      padding: 15px 15px;
    }
    &_btn {
      background: #F8CC55;
      border: 1px solid #F8CC55;
      border-radius: 3px;
      font-size: 14px;
      color: #000000;
      text-align: center;
      float: right;
      padding: 5px 10px;
      cursor: pointer;
      width: 100%;
      @media (min-width: 768px) {
        width: initial;
      }
    }
    &_switchBtn {
      background: #E2E5ED;
      border: 1px solid #E2E5ED;
      border-radius: 3px;
      font-size: 14px;
      color: #000000;
      text-align: center;
      float: right;
      padding: 5px 10px;
      cursor: pointer;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 600;
      position: relative;
      @media (min-width: 768px) {
        display: none;
      }
      &--arrow {
        position: absolute;
        font-size: 24px;
        right: 10px;
        top: 4px;
      }
    }
  }  
</style>
