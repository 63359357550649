<template>
  <div class="Panel">
    
    <div class="Panel_header">
      <button class="Panel_cancelBtn" v-on:click="cancelTask()">
        <i class="fas fa-chevron-right"></i>
      </button>

      <div class="Panel_title" v-if="state == 0">{{ $t("message.task_create_title") }}</div>
      <div class="Panel_title" v-if="state == 1">{{ $t("message.task_edit_title") }}</div>

      <button class="Panel_btn2" v-if="verbatimLink !== '' && verbatimLink !== null" v-on:click="$emit('open-verbatim' , edit_data.verbatimLink)">{{ $t("message.details") }}</button>

      <Badge text="Anonymised" v-if="isAnonymised == true" />
      <Badge text="Hot Alert" v-if="isHotAlert == true" />
      <Badge :text="status_map[status].title" :color="status_map[status].color" v-if="status !== null " />
      
    </div>
    
    <div class="Panel_form">
      <div class="Panel_inputContainer">
        <input :placeholder='$t("message.text")' v-model="title" :class="['Panel_input', title_error ? 'error' : '' ]" />
      </div>
      <SelectForm 
        :error="priority_error" 
        v-model="priority" 
        :placeholder='$t("message.priority")' 
        v-bind:options="[{ id: 1, text: $t('message.low') },{ id: 2, text: $t('message.average') },{ id: 3, text: $t('message.high') }]" 
        icon="fas fa-exclamation" 
      />
      <SelectForm 
        :error="impact_error" 
        v-model="impact" 
        :placeholder='$t("message.impact")' 
        :options="[{ id: 1, text: $t('message.low') },{ id: 2, text: $t('message.average') },{ id: 3, text: $t('message.high') }]" 
        icon="fas fa-bolt" 
      />
      <div :class="['Panel_calendarContainer', cal_data_error ? 'error' : '' ]">
        <div class="Panel_calendarPrefix">
          <i class="far fa-calendar-alt"></i>
        </div>
        <datepicker :placeholder='$t("message.date")' v-model="cal_data" calendar-class="calendar-position" 
          :class="['Panel_calendar', cal_data_error ? 'error' : '' ]" :disabled-dates="pastDates" />
      </div>
      <div class="Panel_userPicker">
        <UserPicker 
          v-on:selectedUsers="onSelectedUsers" 
          :error="users_error" 
          ref="usersComponent"
          :pass_users="users_handle"
        />
      </div>
      <div class="Panel_selectContainer">
        <div :class="['Panel_motPrefix', kpi_error ? 'error' : '']">
          <i class="fas fa-crosshairs"></i>
        </div>
        <select :class="['Panel_select', kpi_error ? 'error' : '', state == 1 ? 'disabled' : '']" v-model="kpi" :disabled="state == 1">
          <option :value="null" selected>{{ $t("message.select_kpi") }}</option>
          <option v-for="item in kpi_options" v-bind:key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    
    <div class="Panel_fields">

      <!-- REASONS -->
      <TaskBox :title="$t('message.specific_reasons')" icon="far fa-thumbs-down" v-if="reasons && reasons.length > 0">
        <div class="Panel_reasons">
          <div v-for="(reason,index) in reasons" v-bind:key="index" :class="['Panel_reasonContainer', reason.active ? '' : 'inactive']" :id="'reason_row_' + index">
            <div class="Panel_reasonText">{{ reason.text }}</div>
            <div class="Panel_reasonBtn" v-on:click="selectReason(index)" :id="'reason_btn_' + index">
              <i :class="[ 'fas', reason.active ? 'fa-eye' : 'fa-eye-slash' ]"></i>
            </div>
          </div>
        </div>
      </TaskBox>

      <!-- START INFO MESSAGES -->
      <div class="Panel_taskInfo" v-if="state == 0">
        <div>
          <i class="fas fa-info-circle"></i>
        </div>
        <div>
          {{ $t("message.task_info_create") }}
        </div>
      </div>

      <!-- PROCESS INFO MESSAGES -->
      <div class="Panel_taskInfo" v-if="state == 1">
        <div>
          <i class="fas fa-info-circle"></i>
        </div>
        <div>
          {{ $t("message.task_info") }}
        </div>
      </div>
      
      <!-- CREATE SUBTASKS -->
      <TaskBox :title="$t('message.create_task_txt')" icon="far fa-check-circle" v-if="state == 0">
        <div class="Panel_tasks">
          <div v-for="(subtask,index) in subtasks" v-bind:key="index" class="Panel_taskContainer">
            <div class="Panel_taskText">{{ subtask }}</div>
            <div class="Panel_taskBtn" v-on:click="removeSubtask(index)" :id="'subtask_btn_' + index">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
          <div class="Panel_taskInputContainer">
            <input class="Panel_taskInput"  v-bind:placeholder="$t('message.type_input_placeholder')" v-on:keyup.enter="addSubtask()"
              v-model="subtask_text" type="text"/>
            <button class="Panel_taskInputBtn" v-on:click="addSubtask()">
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
          <div v-show="subtask_error" class="Panel_error">{{ $t("message.empty") }}</div>
        </div>
      </TaskBox>

      <!-- EDIT SUBTASKS-->
      <TaskBox :title="$t('message.performed_task')" icon="far fa-check-circle" v-if="subtasks.length > 0 && state == 1">
        <div class="Panel_tasks">
          <div v-for="subtask in subtasks" v-bind:key="subtask.id" class="Panel_taskContainer2">
            <div class="Panel_taskBtn">
              <label class="Panel_checkboxContainer">
                <input type="checkbox" :checked="subtask.done" v-on:click="doneSubtask(subtask)">
                <span class="Panel_checkmark"></span>
              </label>
            </div>
            <div class="Panel_taskText">{{ subtask.content }}</div>
          </div>
        </div>
      </TaskBox>

      <!-- DESCRIPTION -->
      <TaskBox :title="$t('message.extra_description')" icon="fas fa-edit" >
        <TextareaForm
          v-bind:placeholderText="$t('message.type_input_placeholder')"
          v-model="description" 
        />
      </TaskBox>

      <!-- CONTACTED CUSTOMER TOGGLE -->
      <TaskBox :title="$t('message.customer_contacted')" icon="fas fa-fire" v-if="state == 1">
        <label class="Panel_switch">
          <input type="checkbox" v-model="contacted_customer" :disabled="!hot_alert_manager && isHotAlert" >
          <span class="Panel_slider"></span>
        </label>
        <span class="Panel_filter">{{ $t('message.customer_contacted_value') }}</span>
      </TaskBox>

      <!-- SOLUTION -->
      <TaskBox :title="$t('message.solution')" icon="far fa-lightbulb" v-if="state == 1">
        <TextareaForm
          v-bind:placeholderText="$t('message.type_input_placeholder')"
          v-model="solution" 
        />
      </TaskBox>

      <!-- COMMENTS AND LOGS -->
      <TaskBox :title="$t('message.comments_and_activities')" icon="fas fa-history" v-if="state == 1">
        <tabs>
          <tab :title="$t('message.comments')">
            <TextareaForm v-bind:placeholderText="$t('message.write_comment')" v-model="comment_text" />
            <div class="Panel_btnCommentBlock">
              <button :class="['Panel_btnComment', comment_text ? '':'disabled' ]" v-on:click="addComment()" :disabled="comment_text == ''">{{ $t("message.add_comment") }}</button>
            </div>
            <div class="Panel_box" v-for="comment in comments" v-bind:key="comment.id">
              <p class="Panel_boxAuthor"><i class="fas fa-user"></i> {{ comment.user }}
                <span class="Panel_boxDate">{{ comment.date }} <i class="far fa-calendar-alt"></i></span>
              </p>
              <div class="Panel_boxContent">{{ comment.content }}</div>
            </div>
          </tab>
          <tab :title="$t('message.activities')">
            <div class="Panel_box" v-for="activity in activities" v-bind:key="activity.id">
              <p class="Panel_boxAuthor"><i class="fas fa-user"></i> {{ activity.user }}
                <span class="Panel_boxDate">{{ activity.date }} <i class="far fa-calendar-alt"></i></span>
              </p>
              <div class="Panel_boxContent">{{ activity.content }}</div>
            </div>
          </tab>
        </tabs>
      </TaskBox>
    </div>

    <div class="Panel_file">
      <label class="Panel_fileLabel">{{ $t("message.file") }}
        <input type="file" id="file" ref="file" class="Panel_fileInput" v-on:change="handleFileUpload()"/>
      </label>
    </div>

    <div class="Panel_btns">
      
      <button v-if="state == 0" :disabled="btn_disabled" class="Panel_btn" v-on:click="createTask()" v-show="!btn_loading">
        {{ $t("message.create_task") }}
      </button>

      <button v-if="state == 1" :disabled="btn_disabled" class="Panel_btn" v-on:click="editTask()" v-show="!btn_loading">
        {{ $t("message.save_task") }}
      </button>

      <button class="Panel_btn_loading" v-show="btn_loading">
        {{ $t("message.creating") }}
      </button>
    </div>

    <ErrorPopUp v-show="popUp_error" v-on:shop-error-popUp="HidePopUp">
      {{ $t("message.error_task_create") }}
    </ErrorPopUp>

    <SuccessPopUp v-show="popUp_success">
      {{ $t("message.success_task_create") }}
    </SuccessPopUp>

  </div>
</template>
  
<script>
  import SelectForm from './form/SelectForm.vue'
  import UserPicker from './UserPicker.vue'
  import TextareaForm from './form/TextareaForm.vue'
  import Datepicker from 'vuejs-datepicker'
  import TaskBox from './TaskBox.vue'
  import Badge from './Badge.vue'
  import ErrorPopUp from './ErrorPopUp.vue'
  import SuccessPopUp from './SuccessPopUp.vue'
  import axios from 'axios'
  import { Tabs, Tab } from 'vue-slim-tabs'

  export default {
    data() {
      console.log(this.kpi_options);
      return {
        title: '',
        title_error: false,
        priority: null,
        priority_error: false,
        impact: null,
        impact_error: false,
        kpi: null,
        kpi_options: this.kpi_options ? this.kpi_options : [],
        kpi_error: false,
        cal_data: '',
        cal_data_error: false,
        btn_disabled: false,
        btn_loading: false,
        users: [],
        users_handle: [],
        description: '',
        subtasks: [],
        subtask_text: '',
        subtask_error: false,
        popUp_error: false,
        popUp_success: false,
        users_error: false,
        contacted_customer: false,
        file: '',
        reasons: [],
        state: 0, // 0 -new ; 1 = edit
        comments: [],
        activities: [],
        solution: '',
        comment_text: '',
        verbatimLink: '',
        isHotAlert: '',
        isAnonymised: '',
        status: null,
        hot_alert_manager: false,
        status_map: {
          0: { title: "new", color: "#d0d0d0" },
          1: { title: "in progress", color: "#ff9e1b" },
          2: { title: "overdue", color: "#cb4d53" },
          3: { title: "done", color: "#009b77" }
        }
      }
    },
    props: {
      edit_data: Object // Type: Object is passed by reference and therefor not reactive
    },
    components: {
      SelectForm,
      UserPicker,
      Datepicker,
      TextareaForm,
      TaskBox,
      ErrorPopUp,
      SuccessPopUp,
      Tabs, 
      Tab,
      Badge
    },
    watch: {
      edit_data () {
        this.title = this.edit_data.title
        this.priority = this.edit_data.priority
        this.users_handle = this.edit_data.users
        this.subtasks = this.edit_data.subtasks
        this.impact = this.edit_data.impact
        this.cal_data = this.edit_data.due
        this.description = this.edit_data.content
        this.solution = this.edit_data.solution
        this.comments = this.edit_data.comments
        this.activities = this.edit_data.activity
        this.verbatimLink = this.edit_data.verbatimLink
        this.isHotAlert = this.edit_data.isHotAlert
        this.isAnonymised = this.edit_data.isAnonymised
        this.status = this.edit_data.status
        this.state = 1
        this.reasons = this.edit_data.reasons
        this.contacted_customer = this.edit_data.contacted_customer
        this.hot_alert_manager = this.edit_data.isHotAlertManagerOfTask
        this.kpi_options.forEach(item => {
          if(item.id == this.edit_data.kpi){
            this.kpi = item.id
          }
        })
      }
    },
    mounted () {
      axios.get('/kpi/names').then(response => {
        this.kpi_options = response.data
      }).catch(e => {
        console.log(e)
      })
    },
    computed: {
      pastDates: function () {
        var current_date = new Date()
        var day = current_date.getDate()
        var month = current_date.getMonth()
        var year = current_date.getFullYear()
        var state = {
          disabledDates: {
            to: new Date(year, month, day)
          }
        }
        return state.disabledDates
      }
    },
    methods: {
      doneSubtask(subtask) {
        subtask.done = !subtask.done
      },
      handleFileUpload(){
        this.file = this.$refs.file.files[0];
      },
      cancelTask() {
        this.$emit('cancel-task', true)
        this.$emit('close-verbatim')
        this.cleanData()
      },
      checkAllFields(){
        var test_field = 0;
        if (this.title == '') {
          this.title_error = true
          test_field++
        }
        if (this.priority == null) {
          this.priority_error = true
          test_field++
        }
        if (this.impact == null) {
          this.impact_error = true
          test_field++
        }
        if (!this.cal_data) {
          this.cal_data_error = true
          test_field++
        }
        if (this.kpi == null) {
          this.kpi_error = true
          test_field++
        }
        if (this.subtasks.length == 0 && this.state == 0) {
          this.subtask_error = true
          test_field++
        }
        if (this.users.length == 0) {
          this.users_error = true
          test_field++
        }

        if(test_field == 0) {
          return true  
        }
        else {
          return false
        }
      },
      removeSubtask(subtask_id){
        this.subtasks.splice(subtask_id, 1);
      },
      addSubtask(){
        if( this.subtask_text !== '' ){
          this.subtask_error = false
          this.subtasks.push(this.subtask_text)
          this.subtask_text = ''
        }
        else {
          this.subtask_error = true
        }
      },
      cleanData () {
        var kpi_options = this.kpi_options
        Object.assign(this.$data, this.$options.data())
        this.kpi_options = kpi_options
        this.$refs.usersComponent.selected_users = []
        this.$refs.usersComponent.selected_users_id = []
        this.$refs.usersComponent.users = this.$refs.usersComponent.users_copy
        this.$refs.usersComponent.search = ''
      },
      onSelectedUsers(value){
        this.users = value
      },
      createTask () {
        if (this.checkAllFields()){
          
          this.btn_loading = true
          this.block_form = true

          let formData = new FormData();

          formData.append('subject', this.title);
          formData.append('priority', this.priority);
          formData.append('due', this.cal_data.toISOString().slice(0,10));
          formData.append('impact', this.impact);
          formData.append('kpi_id', this.kpi);
          formData.append('recipients', this.users);
          formData.append('description', this.description);
          formData.append('subtasks', JSON.stringify(this.subtasks));
          formData.append('file', this.file);

          axios.post('/task-manager/create-task',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(response => {
            this.popUp_success = true
            setTimeout(this.cancelTask(), 5000);
            this.$emit('close-verbatim')
          })
          .catch(e => {
            this.popUp_error = true
            console.log(e)
          })
        }
      },
      editTask () {
        if (this.checkAllFields()){

          this.btn_loading = true
          this.block_form = true

          let taskData = JSON.stringify({
            subject: this.title,
            priority: this.priority,
            due: this.cal_data,
            impact: this.impact,
            kpi_id: this.kpi,
            recipients: this.users,
            description: this.description,
            solution: this.solution,
            subtasks: this.subtasks,
            reasons: this.reasons,
            contacted_customer: this.contacted_customer
          });

          let formData = new FormData();
          formData.append('taskData', taskData);
          formData.append('file', this.file);

          axios.post('/task-manager/save-task/' + this.edit_data.id,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )

          .then(response => {
            this.cancelTask()
          })
          .catch(e => {
            this.popUp_error = true
            console.log(e)
          })
        }
      },
      HidePopUp (value) {
        this.popUp_error = value
      },
      selectReason(reason_id) {
        var selected_id = "reason_row_" + reason_id
        var reason_row = document.getElementById(selected_id)
        reason_row.classList.toggle("inactive")
        var icon = document.getElementById('reason_btn_' + reason_id).getElementsByTagName('i')[0]
        icon.classList.toggle("fa-eye-slash")
        icon.classList.toggle("fa-eye")
      },
      addComment(){
        let formData = new FormData();

        formData.append('content', this.comment_text);

        axios.post('/task-manager/comment-task/' + + this.edit_data.id,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => {
          this.comments.unshift(response.data)
          this.comment_text = ''
        }).catch(e => {
          console.log(e)
        })
      }
    }
  }
</script>
  
<style scoped lang="less">
  .Panel {
    width: 100%;
    background: #fff;
    box-shadow: -1px 0px 16px #00000029;
    box-sizing: border-box;
    height: 100vh;
    overflow: auto;
    font-family: 'Open Sans', sans-serif;
    position: fixed;
    top: 0px;
    right: -490px;
    transition: right 1s;
    float: left;
    z-index: 9998;
    padding-bottom: 20px;
    @media (min-width: 470px) {
      z-index: 9999;
      width: 470px;
    }
    &.open {
      right: 0px;
    }
    &_header {
      padding: 10px 15px;
      width: 100%;
      float: left;
      box-sizing: border-box;
    }
    &_title {
      font-weight: 700;
      color: #666666;
      font-size: 19px;
      float: left;
      line-height: 29px;
    }
    &_cancelBtn {
      background: transparent;
      border: 0px solid transparent;
      font-size: 16px;
      color: #EFEFF0;
      text-align: center;
      float: left;
      padding: 2px 0px;
      cursor: pointer;
      margin-right: 10px;
    }
    &_form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      padding-bottom: 20px;
      float: left;
      padding: 0px 15px;
      width: 100%;
      box-sizing: border-box;
      @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &_fields {
      padding-bottom: 20px;
      float: left;
      padding: 20px 15px 0px 15px;
      width: 100%;
      box-sizing: border-box;
    }
    &_file {
      padding-bottom: 20px;
      float: left;
      padding: 0 15px 20px 15px;
      width: 100%;
      box-sizing: border-box;  
    }
    &_btns {
      padding-bottom: 20px;
      float: left;
      padding: 0px 15px;
      width: 100%;
      box-sizing: border-box;
    }
    &_inputContainer {
      width: 100%;
      position: relative;
      @media (min-width: 992px) {
        grid-column: span 3;
      }
    }
    &_input {
      width: 100%;
      height: 34px;
      box-sizing: border-box;
      font-size: 14px;
      border: 1px solid #EFEFF0;
      border-radius: 3px;
      padding: 3px 10px;
      font-family: 'Open Sans', sans-serif;
      position: relative;
      background: #FBFBFB;
      color: #666666;
      &.error {
        border-color: #ff2400;
      }
    }
    &_fileLabel {
      width: 100%;
      margin: 0px;  
    }
    &_fileInput {
      width: 100%;
      padding: 0px;
      height: 28px;
      &::-webkit-file-upload-button {
        background: #0391ea;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        border: 0px solid transparent;
        box-sizing: border-box;
        height: 26px;
        text-transform: uppercase;
        padding: 3px 6px 3px 6px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
      }
    }
    /deep/ &_calendar input {
      width: 100%;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid #EFEFF0;
      border-radius: 3px;
      padding: 3px 10px 3px 32px;
      font-size: 12px;
      color: #666666;
      background: #FBFBFB;
    }
    /deep/ &_calendar.error input {
      border-color: #ff2400;
    }
    &_selectContainer {
      width: 100%;
      position: relative;
      @media (min-width: 992px) {
        grid-column: span 3;
      }
    }
    &_userPicker {
      @media (min-width: 992px) {
        grid-column: span 3;  
      }
    }
    &_calendarPrefix {
      background: #efeff0;
      color: #666;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px 2px;
    }
    &_calendarContainer {
      width: 100%;
      position: relative;
      &.error .Panel_calendarPrefix {
        background: #ff2400;
        color: #fff;
      }
    }
    &_selectContainer {
      width: 100%;
      position: relative;
      @media (min-width: 992px) {
        grid-column: span 3;
      }
    }
    &_motPrefix {
      background: #efeff0;
      color: #666;
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 1;
      border-radius: 3px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px 2px;
      &.error {
        background: #ff2400;
        color: #fff;
      }
    }
    &_select {
      width: 100%;
      height: 30px;
      box-sizing: border-box;
      font-size: 12px;
      border: 1px solid #EFEFF0;
      border-radius: 3px;
      padding: 5px 10px 5px 32px;
      font-family: 'Open Sans', sans-serif;
      background: #FBFBFB;
      color: #666666;
      &.error {
        border-color: #ff2400;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    &_btn {
      background: #0391EA;
      border: 0px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      float: right;
      padding: 3px 30px 3px 6px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      height: 26px;
      box-sizing: border-box;
      @media (min-width: 768px) {
        width: initial;
      }
      &:after {
        position: absolute;
        content: "\f044";
        color: #fff;
        background: rgba(102,102,102,.3);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        text-align: center;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 0px;
        right: 0px;
        font-size: 15px;
        padding: 2px 0px;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: #D0D0D0;
      }
    }
    &_btn2 {
      background: #0391EA;
      border: 0px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      float: right;
      padding: 3px 30px 3px 6px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      height: 26px;
      box-sizing: border-box;
      @media (min-width: 768px) {
        width: initial;
      }
      &:after {
        position: absolute;
        content: "\f129";
        color: #fff;
        background: rgba(102,102,102,.3);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        text-align: center;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 0px;
        right: 0px;
        font-size: 15px;
        padding: 2px 0px;
      }
    }
    &_btn_loading {
      background: #28a745;
      border: 0px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      float: right;
      padding: 3px 30px 3px 6px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      height: 26px;
      box-sizing: border-box;
      cursor: not-allowed;
      @media (min-width: 768px) {
        width: initial;
      }
      &:after {
        position: absolute;
        content: "\f110";
        color: #fff;
        background: rgba(102,102,102,.3);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        text-align: center;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 0px;
        right: 0px;
        font-size: 15px;
        padding: 2px 0px;
      }
    }
    /* INFO TASK */
    &_taskInfo {
      width: 100%;
      background: #0391ea;
      color: #fff;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 40px 1fr;
      border-radius: 3px;
      padding: 5px;
      align-items: center;
      justify-items: center;
      margin-bottom: 20px;
      & i {
        font-size: 35px;
      }
    }
    /* TASKS */
    &_tasks {
      float: left;
      width: 100%;
      background: #fff;
    }
    &_taskContainer {
      display: grid;
      grid-template-columns: 1fr 40px;
      border-radius: 3px;
      border: 1px solid #efeff0;
      margin-bottom: 5px;
    }
    &_taskContainer2 {
      display: grid;
      grid-template-columns: 40px 1fr;
      border-radius: 3px;
      border: 1px solid #efeff0;
      margin-bottom: 5px;
    }
    &_taskText {
      color: #666;
      font-size: 12px;
      padding: 10px 10px;
    }
    &_taskBtn {
      padding: 10px 10px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
    &_taskInputContainer {
      position: relative;
    }
    &_taskInput {
      width: 100%;
      box-sizing: border-box;
      float: left;
      padding: 10px 40px 10px 10px;
      font-size: 12px;
      color: #666;
      height: 36px;
      border-radius: 3px;
      border: 1px solid #efeff0;
    }
    &_taskInputBtn {
      color: #666;
      width: 40px;
      height: 38px;
      box-sizing: border-box;
      position: absolute;
      right: 0px;
      border: 0px solid transparent;
      font-size: 14px;
      text-align: center;
      background: transparent;
      cursor: pointer;
    }
    /* checkbox */
    /* The container */
    &_checkboxContainer {
      display: block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      top: 0px;
      left: 2px;
    }

    /* Hide the browser's default checkbox */
    &_checkboxContainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    &_checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &_checkboxContainer:hover input ~ &_checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    &_checkboxContainer input:checked ~ &_checkmark {
      background-color: #0391ea;
    }

    &_checkboxContainer input:disabled ~ &_checkmark {
      opacity: 0.8;
      cursor: not-allowed;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    &_checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    &_checkboxContainer input:checked ~ &_checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    &_checkboxContainer &_checkmark:after {
      left: 6px;
      top: 3px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &_error {
      float: left;
      width: 100%;
      color: #721c24;
      border-top: 1px solid #f5c6cb;
      box-sizing: border-box;
      background: #f8d7da;
      padding: 10px;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
    }
    &_verbatimBtn {
      position: absolute;
      top: 0;
      right: 35px;
      border: 0 solid transparent;
      color: #999999;
      font-size: 11px;
      height: 42px;
      box-sizing: border-box;
      padding: 5px 10px;
      cursor: pointer;
      text-decoration: underline;
      &:before {
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f06e";
      }
    }
    /* REASON */
    &_reasons {
      float: left;
      width: 100%;
    }
    &_reasonContainer {
      display: grid;
      grid-template-columns: 1fr 40px;
      border-radius: 3px;
      border: 1px solid #EFEFF0;
      margin-bottom: 5px;
      background: #fff;
      &:last-of-type {
        margin-bottom: 0px;
      }
      &.inactive {
        background: #efefef;
        opacity: 0.6;
      }
    }
    &_reasonText {
      color: #666;
      font-size: 12px;
      padding: 10px 10px;
    }
    &_reasonBtn {
      padding: 10px 10px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
    /* COMMENTS & LOGS */
    &_box {
      border: 1px solid #EFEFF0;
      background: #fff;
      border-radius: 3px;
      float: left;
      width: 100%;
      margin-top: 15px;
      padding: 10px 10px;
      box-sizing: border-box;
    }
    &_boxAuthor {
      color: #666666;
      font-size: 14px;
      text-align: left;
      margin: 0px;
      padding-bottom: 5px;
    }
    &_boxContent {
      color: #666666;
      font-size: 14px;
      width: 100%;
    }
    &_boxDate {
      color: #999999;
      font-size: 11px;
      float: right;
      font-weight: 600;
    }
    &_btnCommentBlock {
      width: 100%;
      float: left;
      padding: 5px 0px;
    }
    &_btnComment {
      background: #adacac;
      border: 0px solid transparent;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      float: right;
      padding: 3px 30px 3px 6px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      height: 26px;
      box-sizing: border-box;
      @media (min-width: 768px) {
        width: initial;
      }
      &:after {
        position: absolute;
        content: "\f044";
        color: #fff;
        background: rgba(102,102,102,.3);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        text-align: center;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 0px;
        right: 0px;
        font-size: 15px;
        padding: 2px 0px;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    /* TOGGLE */
    &_switch {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 20px;
      margin-right: 5px;
      margin-bottom: 0px;
    }
    &_switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    &_slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s;
      border-radius: 34px;
      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 3px;
        bottom: 2px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
      }
    }
    &_switch input:checked + &_slider {
      background-color: #0391EA;
    }
    &_switch input:focus + &_slider {
      box-shadow: 0 0 1px #0391EA;
    }
    &_switch input:checked + &_slider:before {
      transform: translateX(17px);
    }
  }

  /* width */
    ::-webkit-scrollbar {
    width: 10px;
  }
    
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee; 
    border-radius: 10px;
  }
     
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
  }
    
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
  }

  /deep/ .calendar-position {
    right: 0px;
  }
  
  /deep/ .vdp-datepicker__calendar {
    font-family: 'Open Sans', sans-serif;
  }

  /* TABS */

  /deep/ .vue-tab {
    color: #B7B8BA;
    font-size: 12px;
    padding: 5px 10px;
    font-family: 'Open sans', sans-serif;
    position: relative;
    border-radius: 6px 6px 0px 0px;
    text-transform: uppercase;
    font-weight: 600;
    top: 0px;
    border: 0px solid transparent;
    background: transparent;
    &:first-of-type:before {
      font-family: "Font Awesome 5 Free"; 
      font-weight: 900; 
      content: "\f2f1";
      padding-right: 7px;
    }
    &:last-of-type:before {
      font-family: "Font Awesome 5 Free"; 
      font-weight: 900; 
      content: "\f1da";
      padding-right: 7px;
    }
  }

  /deep/ .vue-tab[aria-selected="true"] {
    color: #666666;
    border: 0px solid transparent;
    background: #EFEFF0;
  }

  /deep/ .vue-tablist {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    float: left;
    border-bottom: 0px solid transparent;
  }

  /deep/ .vue-tabpanel {
    float: left;
    width: 100%;
    background: #EFEFF0;
    border-radius: 0px 0px 3px 3px;
    padding: 15px;
  }
</style>
