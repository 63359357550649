<template>
  <div class="BadgeBlock" :style="{ background: color }">
    {{ text }}
  </div>
</template>
  
<script>
  export default {
    props: {
      text: String,
      color: String
    }
  }
</script>
  
<style scoped lang="less">
  .BadgeBlock {
    color: #fff;
    padding: 4px 10px;
    background: grey;
    text-transform: uppercase;
    border-radius: 15px;
    font-size: 10px;  
    display: inline-block;
    margin: 0px 3px;
    float: right;
    position: relative;
    top: 2px;
    box-sizing: border-box;
    height: 22px;
    line-height: 15px;
  }
</style>