<template>
    <div>
      <PanelVerbatim 
        v-bind:class="{ 'open' : openVerbatimPanel }"
        :data_dealer="interview"
        v-on:close-verbatim="openVerbatimPanel = false"
      />
      <PanelTasks 
        v-bind:class="{ 'open' : openPanel }"
        :tasks="tasks"
        v-on:cancel-tasks="cancelTaskPanel"
        v-on:showTask="showTask"
        v-on:createTask="createTask"
      />
      <PanelCreateTask 
        :edit_data="edit_data"
        v-bind:class="{ 'open' : openTask }"
        v-on:open-verbatim="openVerbatim"
        v-on:close-verbatim="closeVerbatim"
        v-on:cancel-task="cancelTask"
      /> 
    </div>
  </template>
  
  <script>
    import PanelCreateTask from './components/PanelCreateTask.vue'
    import PanelTasks from './components/PanelTasks.vue'
    import PanelVerbatim from './components/PanelVerbatim.vue' 
    
    import axios from 'axios'
    
    export default {
      data() {
        return {
          openPanel: false,
          openTask: false,
          openVerbatimPanel: false,
          tasks: [],
          edit_data: {},
          interview: {}
        }
      },
      components: {
        PanelCreateTask,
        PanelTasks,
        PanelVerbatim
      },
      mounted: function() {
        this.$i18n.locale = 'en_US'
        this.$i18n.locale = document.querySelector('#js-language').textContent
  
        var verbatimId
        var ref = this
        document.querySelectorAll('.js-tasks-view').forEach(item => {
          item.addEventListener('click', e => {
            verbatimId = e.toElement.getAttribute('data-verbatim')
            ref.viewTasks(verbatimId)
          })
        })
      },
      methods: {
        /*startPanel(){
          this.openPanel = !this.openPanel
        },*/
        cancelTaskPanel(){
          this.openPanel = false
        },
        cancelTask(){
          this.openTask = false
          this.openVerbatimPanel = false
        },
        createTask(){
          this.openPanel = false
          this.openTask = true
        },
        viewTasks(verbatimId){
          var apiLink = '/record-tasks/verbatim/' + verbatimId
          axios.get(apiLink).then(
            response => {
              this.tasks = response.data
              this.openPanel = true
            }
          )
        },
        showTask(id){
          var apiLink = '/task-manager/task/' + id
          axios.get(apiLink).then(
            response => {
              this.edit_data = response.data
              this.openPanel = false
              this.openTask = true
            }
          )
        },
        openVerbatim(val){
          this.openVerbatimPanel = true
          axios.get(val).then(
            response => {
              this.interview = response.data
            }
          )
        },
        closeVerbatim(){
          this.openVerbatimPanel = false
        }
        
        /*,
        openVerbatim(val){
          this.openVerbatimPanel = true
          axios.get(val).then(
            response => {
              this.interview = response.data
            }
          )
        },
        closeVerbatim(){
          this.openVerbatimPanel = false
        }*/
      }
    }
  </script>
  <style lang="less">
      
    /deep/ body {
      overflow-x: hidden;
    }
  </style>
