<template>
  <div class="Panel">
      
    <div class="Panel_header">
      <button class="Panel_cancelBtn" v-on:click="cancelPanel()">
        <i class="fas fa-chevron-right"></i>
      </button>
      <div class="Panel_title">{{ tasks.length }} {{ $t("message.tasks") }}</div>
    </div>
      
    <div class="Panel_tasks" v-for="task in tasks" v-bind:key="task.taskId">
      <div :class="['Panel_task', task.allowedToView ? '' : 'disabled']" v-on:click="openTask(task.taskId,task.allowedToView)">
        <span>{{ task.title }}</span>
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>

    <div class="Panel_tasks">
      <div class="Panel_task" v-on:click="$emit('createTask')">
        <span>
          <i class="fas fa-edit"></i>
          {{ $t("message.create_task") }}
        </span> 
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>

  </div>
</template>
    
<script>
  export default {
    data() {
      return {}
    },
    props: {
      tasks: Object
    },
    methods: {
      cancelPanel() {
        this.$emit('cancel-tasks', true)
      },
      openTask(id,ableTo){
        if(ableTo){
          this.$emit('showTask', id)
        }
      }
    }
  }
</script>
    
<style scoped lang="less">
    .Panel {
      width: 100%;
      background: #fff;
      box-shadow: -1px 0px 16px #00000029;
      box-sizing: border-box;
      height: 100vh;
      overflow: auto;
      font-family: 'Open Sans', sans-serif;
      position: fixed;
      top: 0px;
      right: -490px;
      transition: right 1s;
      float: left;
      z-index: 9998;
      padding-bottom: 20px;
      @media (min-width: 470px) {
        z-index: 9999;
        width: 470px;
      }
      &.open {
        right: 0px;
      }
      &_header {
        padding: 10px 15px;
        width: 100%;
        float: left;
        box-sizing: border-box;
      }
      &_title {
        font-weight: 700;
        color: #666666;
        font-size: 19px;
        float: left;
        line-height: 29px;
      }
      &_cancelBtn {
        background: transparent;
        border: 0px solid transparent;
        font-size: 16px;
        color: #EFEFF0;
        text-align: center;
        float: left;
        padding: 2px 0px;
        cursor: pointer;
        margin-right: 10px;
      }
      &_tasks {
        padding: 10px 15px;
        width: 100%;
        float: left;
      }
      &_task {
        background: rgb(251,251,251);
        border-radius: 3px;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        color: #666;
        text-align: left;
        & i.fa-chevron-right {
          float: right;
          font-size: 20px;
          color: #bbbbbb;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
</style>
