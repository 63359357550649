<template>
  <div class="BasicData">
    <tabs>
      <tab :title="$t('message.dealer_info')">
        <p class="BasicData_text" v-show="dealer_code">{{ $t("message.dealer_code") }}: {{ dealer_code }}</p>
        <p class="BasicData_text" v-show="dealer_name">{{ $t("message.dealer_name") }}: {{ dealer_name }}</p>
        <p class="BasicData_text" v-show="interview_date">{{ $t("message.interview_date") }}: {{ interview_date }}</p>
        <p class="BasicData_text" v-show="service_date">{{ $t("message.service_date") }}: {{ service_date }}</p>
      </tab>
      <tab :title="$t('message.customer_info')">
        <p class="BasicData_text" v-if="client && (client.firstname || client.lastname)">{{ $t("message.name") }}: {{ client.firstname }} {{ client.lastname }}</p>
        <p class="BasicData_text" v-if="client && client.phonenumber">{{ $t("message.phonenumber") }}: {{ client.phonenumber }}</p>
        <p class="BasicData_text" v-if="client && client.email">{{ $t("message.email") }}: {{ client.email }}</p>
        <p class="BasicData_text" v-if="client && client.language">{{ $t("message.language") }}: {{ client.language }}</p>
        <p class="BasicData_text" v-if="client && client.model">{{ $t("message.model") }}: {{ client.model }}</p>
        <p class="BasicData_text" v-if="client && client.vin">{{ $t("message.vin") }}: {{ client.vin }}</p>
        <p class="BasicData_text" v-if="client && client.licencePlate">{{ $t("message.licencePlate") }}: {{ client.licencePlate }}</p>
      </tab>
    </tabs>
  </div>
</template>

<script>
  import { Tabs, Tab } from 'vue-slim-tabs'

  export default {
    components: {
      Tabs, Tab
    },
    props: {
      dealer_code: String,
      dealer_name: String,
      interview_date: String,
      service_date: String,
      client: {
        type: Object,
        default: ()=>{}
      }
    }
  }
</script>

<style src="vue-slim-tabs/themes/default.css"></style>
<style scoped lang="less">
  .BasicData {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px 15px 15px;
    font-family: 'Open sans', sans-serif;
    &_text {
      color: #666666;
      font-size: 13px;
      margin: 5px 0px;
    }
  }

  /deep/ .vue-tab {
    color: #B7B8BA;
    font-size: 12px;
    padding: 5px 10px;
    font-family: 'Open sans', sans-serif;
    position: relative;
    border-radius: 6px 6px 0px 0px;
    text-transform: uppercase;
    font-weight: 600;
    top: 0px;
    border: 0px solid transparent;
    &:first-of-type:before {
      font-family: "Font Awesome 5 Free"; 
      font-weight: 900; 
      content: "\f3c5";
      padding-right: 7px;
    }
    &:last-of-type:before {
      font-family: "Font Awesome 5 Free"; 
      font-weight: 900; 
      content: "\f007";
      padding-right: 7px;
    }
  }

  /deep/ .vue-tab[aria-selected="true"] {
    color: #666666;
    border: 0px solid transparent;
    background: #EFEFF0;
  }

  /deep/ .vue-tablist {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    float: left;
    border-bottom: 0px solid transparent;
  }

  /deep/ .vue-tabpanel {
    float: left;
    width: 100%;
    background: #EFEFF0;
    border-radius: 0px 0px 3px 3px;
    padding: 15px;
  }

</style>
